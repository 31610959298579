import React from "react";
import {Trans, useTranslation, I18nextContext, Link} from 'gatsby-plugin-react-i18next';
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import {graphql} from 'gatsby';
import {StaticImage} from "gatsby-plugin-image";
import {returnLocaleURL} from "../../../utils/utils";
import Solution from "../../../components/solutions/Solution";
import ElectronicLockLineup from '../../../components/solutions/ElectronicLockLineup';
import GatsbySlideshow from "../../../components/carousel/gatsby-slideshow/GatsbySlideshow";

import teamLockers from './images/applications/team-lockers.jpg';
import equipStorage from './images/applications/equipment-storage.jpg';
import staffFurniture from './images/applications/staff-office.jpg';
import CustomerVideo from "../../../components/solutions/CustomerVideo";
import posterSolution from "./images/poster-pro-sports.jpg";

const IndexPage = (data) => {

  const {t} = useTranslation();
  const context = React.useContext(I18nextContext);
  let sl = context.language;
  const d = data.data.Slides.edges;
  const images = [];
  for(let i= 0; i<= d.length-1; i++) {
    images.push(d[i].node.childImageSharp);
  }
  const projectAsides = [
    '<span>united states</span><br>' +
    '<h2>San Francisco 49er\'s</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock 4G Keypad</a></p>',

    '<span>united states</span><br>' +
    '<h2>New Orleans Saints</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Axis Keypad</a></p>',

    '<span>united states</span><br>' +
    '<h2>Jacksonville Jaguars</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock 4G Keypad</a></p>',

    '<span>united states</span><br>' +
    '<h2>Atlanta Falcons</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock 4G Keypad</a></p>',

    '<span>united states</span><br>' +
    '<h2>Iowa State University</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock 4G Keypad</a></p>',

    '<span>united states</span><br>' +
    '<h2>Ohio State University</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">NextLock Axis Keypad</a></p>',

    '<span>united states</span><br>' +
    '<h2>University of Texas</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">NextLock Axis Keypad</a></p>'
  ];
  const solutions = [
    {
      img: teamLockers,
      label: 'team_lockers',
      alt: 'team_lockers',
      bg: 'dark'
    },
    {
      img: equipStorage,
      label: 'equipment_storage',
      alt: 'equipment_storage',
      bg: 'light'
    },
    {
      img: staffFurniture,
      label: 'staff_office_furniture',
      alt: 'staff_office_furniture',
      bg: 'light'
    }
  ]

  return (
      <Layout>
        <Seo title={t('solutions_pro_sports')}
             description={t('des_solutions_pro_sports')}/>
        <div className="solutions">
          <section className="hero --hero-image no-pad">
            <StaticImage
                src="../../../images/industries/hero-pro-sports.jpg"
                quality={100}
                layout={'fullWidth'}
                formats={["auto", "webp", "avif"]}
                alt="Pro Sports"
            />
            <h1>
              <Trans>
                pro_sports
              </Trans>
              <span>
                <Trans>
                  pro_college_hero_subhead
                </Trans>
              </span>
            </h1>
          </section>
          <section className="customer-video healthcare">
            <div className="container">
              <div className={'grid-container'}>
                <h2><Trans>pro_college_subhero_title</Trans></h2>
                <ul>
                  <li><Trans>pro_college_subhero_list_l1</Trans></li>
                  <li><Trans>pro_college_subhero_list_l2</Trans></li>
                  <li><Trans>pro_college_subhero_list_l3</Trans></li>
                  <li><Trans>pro_college_subhero_list_l4</Trans></li>
                  <li><Trans>pro_college_subhero_list_l5</Trans></li>
                  <li><Trans>pro_college_subhero_list_l6</Trans></li>
                </ul>
              </div>
              <div className="video-holder">
                <StaticImage
                    src="./images/sports.jpg"
                    quality={100}
                    width={508}
                    height={312}
                    formats={["auto", "webp", "avif"]}
                    loading={'lazy'}
                    alt="workspace office"
                />
              </div>
            </div>
          </section>
          <ElectronicLockLineup label={'pro_college_lineup_label'} showCurve={true} showAspire={true} showVersa={true} showOrbit={true} showCTA={true} />
          <section className="industry-carousel">
            <div className="container">
              <h2><Trans>pro_college_hero_carousel</Trans></h2>
              <GatsbySlideshow images={images} overlays={projectAsides} />
            </div>
          </section>
          <section className="customer-video healthcare">
            <CustomerVideo
                poster={posterSolution}
                title={'pro_college_customer_video_title'}
                copy={'pro_college_customer_video_copy'}
                vidURL={'https://cdn.mediavalet.com/usva/digilock/0RQkgwClmESK0H2tRVeJ1Q/azWWG6shzkiTkhhD-1fxkA/Widescreen720p/college-sports.mp4'}
            />
          </section>
          <section className="industry-uses">
            <div className="container">
              <h2><Trans>popular_keyless</Trans></h2>
              <Solution items={solutions} />
            </div>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    Slides: allFile(
    filter: {relativeDirectory: {eq: "solutions/pro-sports"}}
    sort: {fields: [name], order: ASC}
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
      }
    }
  }
  
  }
`;
